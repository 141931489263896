

.MuiButton-label  {
    font-family: 'Calibri', 'sans-serif';
}

.MuiInputBase-input {
    font-family: 'Calibri', 'sans-serif' !important;
    font-style: italic !important;
}

.MuiFormLabel-root {
    font-family: 'Calibri', 'sans-serif' !important;
    font-weight: 700 !important;
}
